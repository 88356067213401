import React, { useEffect } from 'react';

const MainPage: React.FC = () => {
    useEffect(() => {
        window.open('https://app.fametoken.com', '_self', '_noreferrer');
    }, []);

    return null;
};

export default MainPage;
